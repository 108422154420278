import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { CookiesProvider, useCookies } from 'react-cookie'; // Import CookiesProvider and useCookies
import './App.css';
import Register from './pages/Register';
import Login from './pages/Login';
import UserPage from './pages/UserPage';

const App = () => {
    const [data, setData] = useState(null);
    const [cookies, setCookie] = useCookies(['user']); // Initialize cookies

    useEffect(() => {
        axios.get('https://askocuk.com.tr/api/data')
            .then(response => setData(response.data))
            .catch(error => console.error('Error fetching data:', error));
    }, []);

    const handleLogin = (user) => {
        setCookie('user', user, { path: '/' });
    };

    return (
        <CookiesProvider>
            <Router>
                <div className="App">
                    <nav className="navbar">
                        <Link to="/" className="navbar-brand">Home</Link>
                        <Link to="/register" className="navbar-link">Register</Link>
                        <Link to="/login" className="navbar-link">Login</Link>
                        <Link to="/user" className="navbar-link">User Page</Link>
                    </nav>
                    <Routes>
                        <Route path="/register" element={<Register />} />
                        <Route path="/login" element={<Login onLogin={handleLogin} />} />
                        <Route path="/user" element={<UserPage username={cookies.user?.username} />} />
                        {/* Add other routes here */}
                    </Routes>
                </div>
            </Router>
        </CookiesProvider>
    );
};

export default App;
