import React, { useEffect, useState } from 'react';
import './UserPage.css';
import SpotifyAuth from '../components/SpotifyAuth';
import { useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import YoutubeAuth from '../components/YoutubeAuth';

const UserPage = ({ username }) => {
  const [cookies, setCookie] = useCookies(['spotify_access_token', 'spotify_refresh_token']);
  const [spotifySuccess, setSpotifySuccess] = useState(false);
  const [spotifyError, setSpotifyError] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const accessToken = searchParams.get('access_token');
    const refreshToken = searchParams.get('refresh_token');
    const success = searchParams.get('spotify_success');
    const error = searchParams.get('spotify_error');

    if (accessToken && refreshToken) {
      setCookie('spotify_access_token', accessToken, { path: '/' });
      setCookie('spotify_refresh_token', refreshToken, { path: '/' });
    }

    setSpotifySuccess(success === 'true');
    setSpotifyError(error === 'true');

    // Remove query parameters from URL
    window.history.replaceState({}, document.title, "/user");
  }, [location, setCookie]);

  return (
    <div className="user-page">
      <div className="top-bar">
        <span>Welcome, {username || 'User'}!</span>
      </div>
      <div className="sidebar">
        <button>My Profile</button>
        <button>Security</button>
      </div>
      <div className="main-content">
        <div className="profile-header">
          <img src="default-profile-picture.jpg" alt="Profile" className="profile-picture" />
          <div className="user-info">
            <h2>{username || 'User Name'}</h2>
          </div>
        </div>
        {spotifySuccess && (
          <div className="alert alert-success">
            Spotify connection successful! Your top artists and tracks have been fetched.
          </div>
        )}
        {spotifyError && (
          <div className="alert alert-error">
            There was an error connecting to Spotify. Please try again.
          </div>
        )}
        <div className="content">
          <div className="content-box">
            <h3>Spotify</h3>
            <SpotifyAuth />
          </div>
          <div className="content-box">
            <h3>YouTube</h3>
            <YoutubeAuth />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserPage;