import React, { useState } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie'; // Import useCookies
import './Login.css';

const Login = ({ onLogin }) => {
  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });
  const [message, setMessage] = useState(''); 
  const [isError, setIsError] = useState(false); 
  const [cookies, setCookie] = useCookies(['user']); // Initialize cookies

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://askocuk.com.tr/api/login', formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setMessage('Login successful!');
      setIsError(false);
      console.log('Login successful:', response.data);
      onLogin(formData); // Call the onLogin function with formData

      // Set cookies for username and password
      setCookie('username', formData.username, { path: '/' });
      setCookie('password', formData.password, { path: '/' });
    } catch (error) {
      setIsError(true);
      if (error.response) {
        if (error.response.status === 404) {
          setMessage('User not found. Please check your username.');
        } else if (error.response.status === 401) {
          setMessage('Invalid password. Please try again.');
        } else {
          setMessage('Login unsuccessful. Please try again.');
        }
      } else if (error.request) {
        setMessage('No response from server. Please try again later.');
      } else {
        setMessage('An error occurred. Please try again.');
      }
      console.error('Login unsuccessful with error:', error);
    }
  };

  return (
    <div className="login-container">
      <form onSubmit={handleSubmit}>
        <h2>Login</h2>
        {message && <p className={`message ${isError ? 'error' : ''}`}>{message}</p>}
        <label>
          Username:
          <input type="text" name="username" value={formData.username} onChange={handleChange} required />
        </label>
        <label>
          Password:
          <input type="password" name="password" value={formData.password} onChange={handleChange} required />
        </label>
        <button type="submit">Login</button>
      </form>
    </div>
  );
};

export default Login;