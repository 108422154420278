import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';

const YoutubeAuth = () => {
  const [youtubeInfo, setYoutubeInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [cookies, setCookie] = useCookies(['youtube_access_token', 'youtube_refresh_token', 'username']);

  const fetchYoutubeInfo = async () => {
    console.log('Fetching YouTube info...');
    try {
      const response = await axios.get(`/api/youtube/user_info?username=${cookies.username}`);
      const likedVideosResponse = await axios.get(`/api/youtube/liked_videos?username=${cookies.username}`);
      const subscriptionsResponse = await axios.get(`/api/youtube/subscriptions?username=${cookies.username}`);
      
      setYoutubeInfo({
        ...response.data,
        likedVideos: likedVideosResponse.data,
        subscriptions: subscriptionsResponse.data
      });
    } catch (error) {
      console.error('Error fetching YouTube info:', error);
      if (error.response && error.response.status === 404) {
        setYoutubeInfo({ channel_title: '', subscriber_count: 0, view_count: 0, playlists: [], likedVideos: [], subscriptions: [] });
      } else {
        setError('Failed to fetch YouTube info. Please try again later.');
      }
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const state = urlParams.get('state');
    const storedState = localStorage.getItem('youtube_auth_state');

    if (code && state && storedState === state) {
      console.log('YouTube code received, exchanging for tokens');
      setLoading(true);
      axios.get(`/api/youtube/callback?code=${code}&state=${state}&username=${encodeURIComponent(cookies.username)}`)
        .then(response => {
          const { success, access_token, refresh_token } = response.data;
          if (success) {
            console.log('New access token received, storing in cookies');
            setCookie('youtube_access_token', access_token, { path: '/' });
            setCookie('youtube_refresh_token', refresh_token, { path: '/' });
            return fetchYoutubeInfo();
          } else {
            throw new Error(response.data.error || 'Failed to connect to YouTube');
          }
        })
        .catch(error => {
          console.error('Error exchanging code for tokens:', error.response ? error.response.data : error.message);
          setError('Failed to connect to YouTube. Please try again.');
        })
        .finally(() => {
          setLoading(false);
          localStorage.removeItem('youtube_auth_state');
          window.history.replaceState({}, document.title, "/user");
        });
    }
  }, [cookies.username]);

  const handleYoutubeLogin = () => {
    setLoading(true);
    axios.get('/api/youtube/login')
      .then(response => {
        console.log('YouTube login response:', response.data);  // Add this line
        const { auth_url, state } = response.data;
        localStorage.setItem('youtube_auth_state', state);
        console.log('Redirecting to:', auth_url);  // Add this line
        window.location.href = auth_url;
      })
      .catch(error => {
        console.error('Error initiating YouTube login:', error);
        setError('Error initiating YouTube login. Please try again.');
        setLoading(false);
      });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      {(!youtubeInfo || (!youtubeInfo.channel_title && youtubeInfo.subscriber_count === 0)) && (
        <div>
          <p>No YouTube data available. This could be because:</p>
          <ul>
            <li>You haven't connected your YouTube account yet.</li>
            <li>Your YouTube account doesn't have any content.</li>
            <li>The app doesn't have the correct permissions to access your data.</li>
          </ul>
          <button onClick={handleYoutubeLogin}>Connect YouTube</button>
        </div>
      )}
      {youtubeInfo && youtubeInfo.channel_title && (
        <div>
          <h2>Your YouTube Channel:</h2>
          <p>Channel Title: {youtubeInfo.channel_title}</p>
          <p>Subscribers: {youtubeInfo.subscriber_count}</p>
          <p>Total Views: {youtubeInfo.view_count}</p>
          <h3>Your Playlists:</h3>
          {youtubeInfo.playlists.length > 0 ? (
            <ul>
              {youtubeInfo.playlists.map((playlist, index) => (
                <li key={index}>{playlist.title}</li>
              ))}
            </ul>
          ) : (
            <p>No playlists found.</p>
          )}
          <h3>Your Liked Videos:</h3>
          {youtubeInfo.likedVideos.length > 0 ? (
            <ul>
              {youtubeInfo.likedVideos.map((video, index) => (
                <li key={index}>{video.title}</li>
              ))}
            </ul>
          ) : (
            <p>No liked videos found.</p>
          )}
          <h3>Your Subscriptions:</h3>
          {youtubeInfo.subscriptions.length > 0 ? (
            <ul>
              {youtubeInfo.subscriptions.map((subscription, index) => (
                <li key={index}>{subscription.title}</li>
              ))}
            </ul>
          ) : (
            <p>No subscriptions found.</p>
          )}
          <button onClick={handleYoutubeLogin}>Reconnect YouTube</button>
        </div>
      )}
    </div>
  );
};

export default YoutubeAuth;
