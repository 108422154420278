import React, { useState } from 'react';
import axios from 'axios';
import './Register.css';

const Register = () => {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
  });
  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://askocuk.com.tr/api/register', formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setMessage('Registration successful!');
      setIsError(false);
      console.log('Registration successful:', response.data);
    } catch (error) {
      setIsError(true);
      if (error.response) {
        if (error.response.status === 429) {
          setMessage('Username or email already exists.');
        } else {
          setMessage('Registration unsuccessful. Please try again.');
        }
      } else if (error.request) {
        setMessage('No response from server. Please try again later.');
      } else {
        setMessage('An error occurred. Please try again.');
      }
      console.error('Registration unsuccessful with error:', error);
    }
  };

  return (
    <div className="register-container">
      <form onSubmit={handleSubmit}>
        <h2>Register</h2>
        {message && <p className={`message ${isError ? 'error' : ''}`}>{message}</p>}
        <label>
          Username:
          <input type="text" name="username" value={formData.username} onChange={handleChange} required />
        </label>
        <label>
          Email:
          <input type="email" name="email" value={formData.email} onChange={handleChange} required />
        </label>
        <label>
          Password:
          <input type="password" name="password" value={formData.password} onChange={handleChange} required />
        </label>
        <button type="submit">Register</button>
      </form>
    </div>
  );
};

export default Register;