import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';

const SpotifyAuth = () => {
  const [spotifyInfo, setSpotifyInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [cookies, setCookie] = useCookies(['spotify_access_token', 'spotify_refresh_token', 'username']);

  const fetchSpotifyInfo = async () => {
    console.log('Fetching Spotify info...');
    try {
      const response = await axios.get(`/api/spotify/user_info?username=${cookies.username}`);
      setSpotifyInfo(response.data);
    } catch (error) {
      console.error('Error fetching Spotify info:', error);
      if (error.response && error.response.status === 404) {
        setSpotifyInfo({ top_artists: [], top_tracks: [] });
      } else {
        setError('Failed to fetch Spotify info. Please try again later.');
      }
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const state = urlParams.get('state');
    const storedState = localStorage.getItem('spotify_auth_state');

    if (code && state && storedState === state) {
      console.log('Spotify code received, exchanging for tokens');
      setLoading(true);
      axios.get(`/api/spotify/callback?code=${code}&state=${state}&username=${encodeURIComponent(cookies.username)}`)
        .then(response => {
          const { success, access_token, refresh_token } = response.data;
          if (success) {
            console.log('New access token received, storing in cookies');
            setCookie('spotify_access_token', access_token, { path: '/' });
            setCookie('spotify_refresh_token', refresh_token, { path: '/' });
            return fetchSpotifyInfo();
          } else {
            throw new Error(response.data.error || 'Failed to connect to Spotify');
          }
        })
        .catch(error => {
          console.error('Error exchanging code for tokens:', error.response ? error.response.data : error.message);
          setError('Failed to connect to Spotify. Please try again.');
        })
        .finally(() => {
          setLoading(false);
          localStorage.removeItem('spotify_auth_state');
          window.history.replaceState({}, document.title, "/user");
        });
    } else if (cookies.spotify_access_token) {
      console.log('Access token found in cookies, fetching Spotify info');
      setLoading(true);
      fetchSpotifyInfo()
        .finally(() => setLoading(false));
    }
  }, [cookies.username, cookies.spotify_access_token]);

  const handleSpotifyLogin = () => {
    setLoading(true);
    axios.get('/api/spotify/login')
      .then(response => {
        const { auth_url, state } = response.data;
        localStorage.setItem('spotify_auth_state', state);
        window.location.href = auth_url;
      })
      .catch(error => {
        console.error('Error initiating Spotify login:', error);
        setError('Error initiating Spotify login. Please try again.');
        setLoading(false);
      });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      {(!spotifyInfo || (spotifyInfo.top_artists.length === 0 && spotifyInfo.top_tracks.length === 0)) && (
        <div>
          <p>No Spotify data available. This could be because:</p>
          <ul>
            <li>You're new to Spotify and haven't listened to enough music yet.</li>
            <li>Your listening history is set to private.</li>
            <li>The app doesn't have the correct permissions to access your data.</li>
          </ul>
          <button onClick={handleSpotifyLogin}>Reconnect Spotify</button>
        </div>
      )}
      {spotifyInfo && (
        <div>
          <h2>Your Top Artists:</h2>
          {spotifyInfo.top_artists.length > 0 ? (
            <ul>
              {spotifyInfo.top_artists.map((artist, index) => (
                <li key={index}>{artist.name}</li>
              ))}
            </ul>
          ) : (
            <p>No top artists data available. You might need to listen to more music.</p>
          )}
          <h2>Your Top Tracks:</h2>
          {spotifyInfo.top_tracks.length > 0 ? (
            <ul>
              {spotifyInfo.top_tracks.map((track, index) => (
                <li key={index}>{track.name} by {track.artists.map(a => a.name).join(', ')}</li>
              ))}
            </ul>
          ) : (
            <p>No top tracks data available. You might need to listen to more music.</p>
          )}
          <button onClick={handleSpotifyLogin}>Reconnect Spotify</button>
        </div>
      )}
    </div>
  );
}

export default SpotifyAuth;